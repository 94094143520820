import { requestApi_get } from '../../lib/api';

const initFormat = {
    searchData: {
        total_pages: 0,
        list: [],
    }
}

const state = {
    hotKeywords: [],      //热门搜索词    
    keyWords: '',           //搜索关键词
    historyKeyWords: '',     //历史搜索关键词
    reloadSearch: false,
    searchData: initFormat.searchData
}

const actions = {
    // 热门搜索词
    requestHotKeywords({ commit }, params = {}) {
        return new Promise(resolve => {
            requestApi_get('/record/hot_keywords', params).then(res => {
                commit('SET_HOT_KEYWORDS', res.hot_list)
                resolve()
            })
        })
    },

    // 搜索
    requestSearch({ commit }, params = {}) {
        return new Promise(resolve => {
            requestApi_get('/works/search', params).then(res => {
                commit('SET_SEARCH_DATA', res)
                resolve();
            })
        })
    },

}

const mutations = {
    SET_SEARCH_DATA(state, data) {
        state.searchData = data;
    },
    SET_HOT_KEYWORDS(state, data) {
        state.hotKeywords = data;
    },

    SET_KEY_WORDS(state, data) {
        state.keyWords = data;
    },
    SET_HISTORY_KEY_WORDS(state, data) {
        state.historyKeyWords = data;
    },
    SET_RELOAD_SEARCH(state, data) {
        state.reloadSearch = data;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}