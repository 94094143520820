import Vue from 'vue'
import App from './App.vue'
import store from './store';
import router from './router';
import VueCookies from 'vue-cookies'

import 'amfe-flexible/index.js';

import 'vant/lib/index.css';
import './assets/css/common.css';

import { Lazyload ,Dialog} from 'vant';
import waterfall from "vue-waterfall2";
import VueClipboard from 'vue-clipboard2'
// import wx from 'weixin-js-sdk'



// import Vconsole from 'vconsole';
// if (process.env.NODE_ENV == 'development') {
//   new Vconsole();
// }



// import { setVM } from './lib/api';



Vue.use(Lazyload)
Vue.use(Dialog)
Vue.use(VueCookies)
Vue.use(waterfall)
Vue.use(VueClipboard)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



// setVM(wm);
