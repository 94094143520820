import { requestApi_get, requestApi_post } from '../../lib/api';
import { toastLoading, closeToast, toastSuccess } from '../../lib/util'
import VueCookies from 'vue-cookies'


const initFormat = {
    userData: {
        user: {
            head_img: '',
            nickname: '',
            user_level: '1',
            user_level_name: '',
            bonus_level: '0',   //大于一百属于原创
        },
        is_follow: 0,
        vip_flag: false,
        work_num: '0',
        follow_num: '0',
        fans_num: '0',
        find_num: '0'
    },
    meData: {
        user: {
            head_img: '',
            nickname: '',
            user_level: '1',
            user_level_name: '',
            bonus_level: '0',   //大于一百属于原创
        },
        is_follow: 0,
        vip_flag: false,
        work_num: '0',
        follow_num: '0',
        fans_num: '0',
        find_num: '0'
    },
    userInviteData: {
        head_img: '',
        id: "",
        invatation_code: "",
        nickname: ""
    },
    works: {
        list: [],
        total_pages: 0,
    },
    follows: {
        list: [],
        total_pages: 0,
    },
    finds: {
        list: [],
        total_pages: 0,
    },
    fans: {
        list: [],
        total_pages: 0,
    },
    inviteData: {
        invite_code: '',
        reg_num: "",
        vip_recharge_num: '',
        re_amount_num: '',
        amount_total: ''
    }
}

const state = {
    meData: initFormat.meData,
    userData: initFormat.userData,       //用户个人信息
    works: initFormat.works,
    follows: initFormat.follows,
    finds: initFormat.finds,
    fans: initFormat.fans,
    userId: 0,
    inviteData: initFormat.inviteData,
    userInviteData: initFormat.userInviteData
}

const actions = {
    // 获取主页用户信息
    requestInfo({ commit }, params = {}) {
        return new Promise(resolve => {
            requestApi_get('/my/info', params).then(res => {
                commit('SET_ME_DATA', res)
                resolve()
            })
        })
    },

    // 获取用户登录信息
    requestInfo2({ commit }, params = {}) {
        return new Promise(resolve => {
            requestApi_get('/my/info', params).then(res => {
                VueCookies.set('invite_code', res.user.invatation_code, 60 * 60 * 24 * 7)
                commit('SET_USER_DATA', res)
                resolve()
            })
        })
    },

    //获取个人作品
    requestHomepage({ commit }, params = {}) {
        return new Promise(resolve => {
            requestApi_get('/my/homepage', params).then(res => {
                commit('SET_WORKS', res)
                resolve()
            })
        })
    },

    //获取关注列表
    requestFollow({ commit }, params = {}) {
        return new Promise(resolve => {
            requestApi_get('/my/follow', params).then(res => {
                commit('SET_FOLLOWS', res)
                resolve()
            })
        })
    },

    // 获取发现列表
    requestFind({ commit }, params = {}) {
        return new Promise(resolve => {
            requestApi_get('/my/find', params).then(res => {
                commit('SET_FIND', res);
                resolve();
            })
        })
    },

    //获取粉丝列表
    requestFans({ commit }, params = {}) {
        return new Promise(resolve => {
            requestApi_get('/my/fans', params).then(res => {
                commit('SET_FANS', res)
                resolve()
            })
        })
    },

    // 关注/取消关注
    requestFollowMe({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            requestApi_post('/my/follow_me', params).then(res => {
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },

    //我的邀请
    requestInvitation({ commit }, params = {}) {
        return new Promise(resolve => {
            requestApi_get('/invited/invitation', params).then(res => {
                commit('SET_INVITE_DATA', res);
                resolve();
            })
        })
    },

    // 我的分享
    requestInvite({ commit }, params = {}) {
        return new Promise(resolve => {
            requestApi_get('/user/invite', params).then(res => {
                commit('SET_USER_INVITE_DATA', res)
                resolve();
            })
        })
    },


    resumeStoreHistory({ commit }, data) {
        return new Promise(resolve => {
            let _data = JSON.parse(sessionStorage.getItem(`homepage_${data}`))
            commit('SET_ME_DATA', _data.meData)
            resolve();
        })

    }

}

const mutations = {
    SET_ME_DATA(state, data) {
        state.meData = data;
    },
    SET_USER_DATA(state, data) {
        state.userData = data;
    },
    SET_WORKS(state, data) {
        state.works = data;
    },
    SET_FOLLOWS(state, data) {
        state.follows = data;
    },
    SET_FIND(state, data) {
        state.finds = data;
    },
    SET_FANS(state, data) {
        state.fans = data;
    },
    SET_USER_ID(state, data) {
        state.userId = data;
    },
    SET_IS_FOLLOW(state, data) {
        state.meData.is_follow = data
    },
    SET_INVITE_DATA(state, data) {
        state.inviteData = data;
    },
    SET_USER_INVITE_DATA(state, data) {
        state.userInviteData = data
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}