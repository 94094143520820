import { requestApi_post } from '../../lib/api';
import { toastLoading, closeToast, toastSuccess } from '../../lib/util'
import VueCookies from 'vue-cookies'
import store from '@/store'

const state = {
}

const actions = {
    //登录
    requestLogin({ commit }, params = {}) {
        return new Promise(resolve => {
            toastLoading("登陆中...");
            requestApi_post('/user/login', params).then(res => {
                closeToast();
                VueCookies.set('user_id', res.id, 60 * 60 * 24 * 7) //登录成功cookies保留userId 7天
                store.dispatch('me/requestInfo2', { user_id: res.id })
                toastSuccess('登录成功').then(() => {
                    resolve()
                })
            })
        })
    },

    //注册步骤1
    requestRegister({ commit }, params = {}) {
        return new Promise(resolve => {
            toastLoading("请稍等...");
            requestApi_post('/user/register', params).then(() => {
                closeToast();
                resolve();
            })
        })
    },

    //注册步骤2
    requestRegister2({ commit }, params = {}) {
        return new Promise(resolve => {
            toastLoading("注册中...");
            requestApi_post('/user/register', params).then(() => {
                closeToast();
                toastSuccess('注册成功').then(() => {
                    resolve()
                })
            })
        })
    },

    //发送验证吗
    requestSendCode({ commit }, params = {}) {
        return new Promise(resolve => {
            requestApi_post('/user/send_code', params).then(res => {
                resolve(res);
            })
        })
    },

    //重置密码
    requestResetPassword({ commit }, params = {}) {
        return new Promise(resolve => {
            toastLoading("提交中...");
            requestApi_post('/user/reset_password', params).then(() => {
                closeToast();
                toastSuccess('设置成功').then(() => {
                    resolve();
                })
            })
        })
    },

    //微信微博登录
    requestEmailLogin({ commit }, params = {}) {
        return new Promise(resolve => {
            toastLoading("登陆中...");
            requestApi_post('/user/email_login', params).then(res => {
                closeToast();
                VueCookies.set('user_id', res.id, 60 * 60 * 24 * 7) //登录成功cookies保留userId 7天
                store.dispatch('me/requestInfo2', { user_id: res.id }).then(() => {
                    store.commit('SET_IS_LOGIN', true)
                    toastSuccess('登录成功').then(() => {
                        resolve();
                    })
                })
            })
        })
    },

    //第三方快捷登录
    requestGetAuth({ commit }, params = {}) {
        return new Promise(resolve => {
            toastLoading("登陆中...");
            requestApi_post('/user/get_auth', params).then(res => {
                closeToast();
                VueCookies.set('user_id', res.id, 60 * 60 * 24 * 7)
                store.dispatch('me/requestInfo2', { user_id: res.id }).then(() => {
                    store.commit('SET_IS_LOGIN', true)
                    toastSuccess('登录成功').then(() => {
                        resolve();
                    })
                });

            })
        })
    },

    //第三方登录注册提交邀请码
    requestOauthRegisterCode({ commit }, params = {}) {
        return new Promise(resolve => {
            toastLoading("提交中...");
            requestApi_post('/user/oauth_register_code', params).then(() => {
                closeToast();
                toastSuccess('提交成功').then(() => {
                    resolve();
                })
            })
        })
    }

}

const mutations = {

}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}