import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import app from './app';
import home from './modules/home'
import search from './modules/search'
import product from './modules/product'
import user from './modules/user'
import collect from './modules/collect'
import me from './modules/me'
import ad from './modules/ad'
import test from './modules/test'

Vue.use(Vuex);

const debug = process.env.NODE_ENV == 'development';

export default new Vuex.Store({
    ...app,
    modules: {
        home,
        search,
        product,
        user,
        collect,
        me,
        ad,
        test
    },
    strict: debug,
    // plugins: debug ? [createLogger()] : []
})