<template>
  <router-link v-if="iRouter !== ''" :to="iRouter">
    <div class="c-image" :class="iClass" :style="{width:iWidth == '100%' ? iWidth : iWidth/37.5 +'rem' , height:iHeight == '100%' ? iHeight : iHeight/37.5 +'rem'}" @click="onClick">
      <div class="c-image-cover"></div>
      <img :src="require('../assets/img/' + iUrl + '.png')" />
    </div>
  </router-link>
  <div v-else class="c-image" :class="iClass" :style="{width:iWidth == '100%' ? iWidth : iWidth/37.5 +'rem' , height:iHeight == '100%' ? iHeight : iHeight/37.5 +'rem'}" @click="onClick">
    <div class="c-image-cover"></div>
    <img :src="require('../assets/img/' + iUrl + '.png')" />
  </div>
</template>

<script>
export default {
  name: "c-image",
  props: {
    iWidth:{
        type:[String,Number],
        default:'100%',
        required:true
    },
    iHeight:{
        type:[String,Number],
        default:'100%',
        required:true
    },
    iClass:{
        type:String,
        default:''
    },
    iUrl: {
      type: String,
      default: "",
      required: true,
    },
    iRouter: {
      type: String,
      default: "",
    },
  },
  methods:{
    onClick(){
      this.$emit('imgClick')
    }
  }
};
</script>

<style>
.c-image-cover { width: 100%;height: 100%;position: absolute;left: 0;top: 0;z-index: 1;}
.c-image img {width: 100%; vertical-align: top;}
</style>