<template>
  <div class="c-horizontal-cue" v-if="show">
      <div class="content">
        <c-image iUrl="horizontal_cue" iClass="icon" :iWidth="52 * 0.6" :iHeight="65 * 0.6" ></c-image>
        <h1>请将手机竖屏操作</h1>
        <p>浏览效果更佳</p>
      </div>
      
  </div>
</template>

<script>
import CImage from "./CImage.vue";
export default {
    name: "c-horizontal-cue",
    components: {
        [CImage.name]: CImage,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
};
</script>

<style>
.c-horizontal-cue{width:100%; height: 100%; position: fixed; left: 0; top: 0; z-index: 999999; background: #F5F5F5; display: flex;  align-items: center; justify-content: center; }
.c-horizontal-cue .icon{margin: 0 auto 16px;}
.c-horizontal-cue h1{font-size:calc(16px * 0.5); color: #2E2E30; text-align: center; font-weight: normal;}
.c-horizontal-cue p{font-size: calc(14px * 0.5);color: #B3B3B3;text-align: center; margin-top: 4px;}
</style>