import Vue from 'vue';
import { Toast, Dialog } from 'vant';
import store from '@/store'

Vue.use(Toast);

export function toastSuccess(txt) {
    return new Promise((resolve) => {
        Toast({
            message: txt || '',
            icon: require('../assets/img/dialog-success.png'),
            overlay: true,
            duration: 1000,
            onClose: function () {
                resolve();
            }
        });
    })
}

export function toastFail(txt) {
    return new Promise((resolve) => {
        Toast({
            message: txt || '',
            icon: require('../assets/img/dialog-fail.png'),
            overlay: true,
            duration: 1000,
            onClose: function () {
                resolve();
            }
        });
    })
}

export function toastLoading(txt) {
    Toast.loading({
        message: txt || '加载中...',
        forbidClick: true,
        duration: 0,
        overlay: true,
    });
}

export function toast(txt, overlay = false) {
    return new Promise(resolve => {
        Toast({
            message: txt,
            position: 'bottom',
            className: 'toast-s',
            overlay: overlay,
            duration: 2000,
            onClose() {
                resolve();
            }
        });
    })

}

export function closeToast() {
    Toast.clear();
}

// 随机生成字符串
export function randomString(num) {
    let _length = num || 1;
    let _str = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz";
    let _strLength = _str.length;
    let _value = "";
    for (let i = 0; i < _length; i++) {
        _value += _str.charAt(Math.floor(Math.random() * _strLength));
    }
    return _value
}

// 弹窗提示
export function dialogAlert(txt) {
    Dialog.alert({
        title: '提示',
        message: txt,
        confirmButtonColor: '##F85659',
        theme: 'round-button',
        overlayStyle: {
            background: 'rgba(0,0,0,0.6) !important'
        }
    })
}

// 自定义弹窗提示
export function dialogAlert2(txt) {
    store.commit('SET_C_DIALOG', true)
    store.commit('SET_C_DIALOG_TXT', txt)
}

// 获取VUE对象
export function setVM(viewModel) {
    VM = viewModel;
}

//判断是否是手机端
export function isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}

//获取url参数
export function getQueryString(name) {
    let _reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let _r = window.location.search.substr(1).match(_reg); //获取url中"?"符后的字符串并正则匹配
    let _context = "";
    if (_r != null)
        _context = _r[2];
    _reg = null;
    _r = null;
    return _context == null || _context == "" || _context == "undefined" ? "" : _context;
}

//获取当前日期后几天的日期
export function getAppointDateFromCurrent(day) {
    let _dd = new Date();
    _dd.setDate(_dd.getDate() + day);//获取AddDayCount天后的日期  
    let _y = _dd.getFullYear();
    let _m = (_dd.getMonth() + 1) < 10 ? "0" + (_dd.getMonth() + 1) : (_dd.getMonth() + 1);//获取当前月份的日期，不足10补0  
    let _d = _dd.getDate() < 10 ? "0" + _dd.getDate() : _dd.getDate();//获取当前几号，不足10补0  
    return _y + "/" + _m + "/" + _d;
}


//获取设备信息
export function deviceInfo() {
    let userAgent = navigator.userAgent;
    let os = {};
    os.userAgent = userAgent;
    os.android = userAgent.match(/(Android)\s+([\d.]+)/) || userAgent.match(/Silk-Accelerated/) ? true : false;
    os.ipad = userAgent.match(/(iPad).*OS\s([\d_]+)/) ? true : false;
    os.iphone = !os.ipad && userAgent.match(/(iPhone\sOS)\s([\d_]+)/) ? true : false;
    os.ios = os.ipad || os.iphone;
    os.wp = userAgent.match(/Windows Phone/) || userAgent.match(/IEMobile/) ? true : false;
    os.supportsTouch = ((window.DocumentTouch && document instanceof window.DocumentTouch) || 'ontouchstart' in window);
    //	if(os.ios) os.iosVer = parseInt(userAgent.match(/OS\s\d+_/)[0].match(/\d+_/)[0]);
    os.weixin = userAgent.match(/MicroMessenger/) ? true : false;
    if (os.weixin) {
        let ver = userAgent.match(/MicroMessenger\/\d+.\d+.\d+/)[0].match(/\d+.\d+.\d+/)[0].split('.');
        os.weixinVer = 0;
        for (let i = 0; i < ver.length; i++) os.weixinVer += parseInt(ver[i]) * Math.pow(10, ver.length - i - 1);
    } //edn if
    os.wxApp = window.__wxjs_environment == 'miniprogram' || userAgent.match(/miniprogram/) || userAgent.match(/miniProgram/) ? true : false;
    os.weibo = userAgent.match(/Weibo/) || userAgent.match(/weibo/) ? true : false;
    os.ali = userAgent.match(/AliApp/) ? true : false;
    os.alipay = os.ali && userAgent.match(/Alipay/) ? true : false;
    os.taobao = os.ali && userAgent.match(/WindVane/) ? true : false;
    os.tianmao = os.ali && userAgent.match(/Tmall/) ? true : false;
    os.netease = userAgent.match(/NewsApp/) ? true : false;
    os.toutiao = userAgent.match(/NewsArticle/) ? true : false;
    os.facebook = userAgent.match(/(FB)/) ? true : false;
    os.baidu = userAgent.match(/Baidu/) ? true : false;
    os.safari = os.ios && userAgent.match(/Safari/) ? true : false;
    os.chrome = userAgent.match(/Chrome/) ? true : false;
    os.firefox = userAgent.match(/Firefox/) ? true : false;
    os.ie = document.documentMode;
    os.edge = userAgent.match(/Edge/) ? true : false;
    os.pc = !(os.android || os.ios || os.wp);
    os.oppo = false;
    os.oppoBrowser = false;
    os.oppoApp = false;
    os.oppoR15 = false;
    os.simulation = userAgent.match(/Test/) ? true : false;
    if (os.ios) {
        os.iphoneX = (screen.width == 375 && screen.height == 812) || (screen.width == 375 && window.innerHeight >= 635) || (window.innerWidth == 724 && window.innerHeight == 375) || (window.innerWidth == 375 && window.innerHeight == 724) || (window.innerWidth == 812 && window.innerHeight == 343) || (window.innerWidth == 343 && window.innerHeight == 812) || (screen.width == 414 && screen.height == 896) || (screen.width == 414 && window.innerHeight >= 719);
        os.iphoneXFull = os.iphoneX && window.innerHeight > 667;
        os.iphone6Plus = (screen.width == 414 && screen.height == 736) || (screen.width == 414 && window.innerHeight >= 622);
        os.iphone6 = (screen.width == 375 && screen.height == 667) || (screen.width == 375 && window.innerHeight <= 603);
        os.iphone5 = (screen.width == 320 && screen.height == 568) || (screen.width == 320 && window.innerHeight >= 460);
        os.iphone4 = (screen.width == 320 && screen.height == 480) || (screen.width == 320 && window.innerHeight <= 450);
        os.screen159 = false;
        os.screen169 = (screen.height == 568 && screen.width == 320) || (screen.height == 667 && screen.width == 375) || (screen.height == 896 && screen.width == 414);
        os.screen189 = false;
        os.screen209 = (screen.height == 812 && screen.width == 375) || (screen.height == 896 && screen.width == 414);
    } //edn if
    else {
        os.miui = userAgent.match(/MI/) || userAgent.match(/Redmi/) ? true : false;
        os.huawei = userAgent.match(/HUAWEI/) || userAgent.match(/HONOR/) ? true : false;
        os.oppo = userAgent.match(/OPPO/) ? true : false;
        os.oppoBrowser = userAgent.match(/OppoBrowser/) ? true : false;
        os.oppoApp = os.oppo && !os.oppoBrowser && !!window.JSCallJava;
        os.oppoR15 = userAgent.match(/PAAM00/) || userAgent.match(/PAAT00/) || userAgent.match(/PACM00/) || userAgent.match(/PACT00/) ? true : false;
        os.vivo = userAgent.match(/vivo/) ? true : false;
        os.screen159 = screen.height / window.innerWidth <= 540 / 360;
        os.screen169 = screen.height / screen.width <= 640 / 360 && screen.height / screen.width < 710 / 360;
        os.screen189 = screen.height / screen.width >= 710 / 360 && screen.height / screen.width < 740 / 360;
        os.screen209 = screen.height / screen.width >= 740 / 360;
    } //edn if
    return os;
}

