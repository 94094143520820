import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { routeList } from './config'
import VueCookies from 'vue-cookies'

Vue.use(Router)


const routes = routeList;

// 解决跳转重复路由报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (to.meta.savePosition) {    //记录页面滚动位置
      if (savedPosition) {
        sessionStorage.setItem('page_scrolltop', savedPosition.y)
        return savedPosition
      }
      else return { x: 0, y: 0 }
    } else {
      return { x: 0, y: 0 }
    }

  },
  routes
})

router.beforeEach((to, from, next) => {
  if (window._hmt && process.env.NODE_ENV != 'development') window._hmt.push(['_trackPageview', to.path]);   //百度trackPageview统计

  if (to.name != 'homepage' && to.name != 'detail') document.title = "享设计 - 作品共享交易平台";

  if (process.env.NODE_ENV == 'development') {
    console.log('from page:', from);
    console.log('to page:', to);
  }

  if (to.name == 'detail' || to.name == 'homepage') store.commit('SET_COMMON_ID', to.params.id)  //用于手机端页面跳到PC指定页面

  if (!(VueCookies.isKey('user_id')) && to.path.indexOf('user') == -1) {    //记录未登录状态路由情况，用于登录后返回当前页
    let _path = to.path;                  
    if (_path == '/invitation') _path = '/'           //未登录状态点击首页我的邀请按钮，将登录后返回页默认改为首页
    sessionStorage.setItem('no_login_route', _path)
  }

  store.dispatch('changeRoute', { to: to, from: from }).then(() => {  //记录路由

    if (to.name == 'detail') store.commit('product/SET_PRODUCT_ID', to.params.id) //跳转到作品详情之前记录作品ID（用做当前页面刷新内容）
    if (to.name == 'homepage') store.commit('me/SET_USER_ID', to.params.id) //跳转到主页之前记录用户ID（用做当前页面刷新内容）

    if (to.meta.checkLogin) {   //验证是否需要登录
      if (VueCookies.isKey('user_id')) {  //是否处于登录状态
        next();
      } else {
        next('/user/login')
      }
    } else {
      next();
    }

  })


});


export default router
