const state = {
    routeName: '',      //当前路由名称
    fromRouteName: '',  //上一个路由名称
    routePath: '',       //当前路由路径
    fromRoutePath: '',   //上一个路由路径
    routeType: 'next',  //判断路由前进或后退
    headerStatus: true,    //头部导航显示状态
    hSearchTop: 0,      //首页大搜索框到页面顶部的距离
    os: {},             //浏览器及设备信息
    showCDialog: false,     //显示隐藏通用弹窗
    CDialogTxt: '',     //通用弹窗文案
    commonId: '',   //用于手机端页面跳到PC指定页面
    isLogin: false,  //用户是否登录
    reloadMe: false,     //homepage刷新
    headerNewsMe: '',     //头部导航与主页之间通讯
    homeScrollTop: 0,    //首页滚动位置
}

const actions = {
    changeRoute({ commit }, data) {
        return new Promise(resolve => {
            commit('SET_ROUTE', data)
            resolve();
        })
    },
    changeHeader({ commit }, data) {
        return new Promise(resolve => {
            commit('SET_HEADER', data)
            resolve();
        })
    },
    changeHomeSearch({ commit }, data) {
        return new Promise(resolve => {
            commit('SET_HOME_SEARCH_TOP', data)
            resolve();
        })
    }
}

const mutations = {
    SET_ROUTE: (state, data) => {
        state.routeName = data.to.name;
        state.routePath = data.to.path;
        state.fromRouteName = data.from.name;
        state.fromRoutePath = data.from.path;
    },
    SET_HEADER: (state, data) => {
        state.headerStatus = data
    },
    SET_HOME_SEARCH_TOP: (state, data) => {
        state.hSearchTop = data;
    },
    SET_OS: (state, data) => {
        state.os = data;
    },
    SET_C_DIALOG: (state, data) => {
        state.showCDialog = data;
    },
    SET_C_DIALOG_TXT: (state, data) => {
        state.CDialogTxt = data;
    },
    SET_ROUTE_TYPE: (state, data) => {
        state.routeType = data;
    },
    SET_COMMON_ID: (state, data) => {
        state.commonId = data;
    },
    SET_IS_LOGIN: (state, data) => {
        state.isLogin = data;
    },
    SET_HEADER_NEWS_ME: (state, data) => {
        state.headerNewsMe = data;
    },
    SET_RELOAD_ME: (state, data) => {
        state.reloadMe = data;
    },
    SET_HOME_SCROLL_TOP: (state, data) => {
        state.homeScrollTop = data;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}