import { requestApi_get } from '../../lib/api';

const initFormat = {
    projectsAd:{
        object_name:'',
        image_link:''
    }
}

const state = {
    projectsAd:initFormat.projectsAd
}

const actions = {
    // 作品列表广告位
    requestAdWaterfall({commit},params={}){
        return new Promise((resolve)=>{
            requestApi_get('/ad/waterfall',params).then(res=>{
                commit('SET_AD_PROJECTS',res)
                resolve()
            })
        })
    }
}

const mutations={
    SET_AD_PROJECTS(state,data){
        state.projectsAd=data;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}