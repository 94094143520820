<template>
  <div class="c-dialog">
      <van-overlay :show="showCDialog" @click="onClose">
          <div class="common-dialog" @click.stop >
                <div class="icon_close" @click="onClose">
                    <c-image iUrl="icon-close_2" iWidth="14" iHeight="14"></c-image>
                </div>
                <c-image iUrl="icon-tipoff" iClass="icon" iWidth="36" iHeight="36" ></c-image>
                <h1>提示</h1>
                <p class="third_cue">{{CDialogTxt}}</p>
          </div>
      </van-overlay>
  </div>
</template>

<script>
import { Overlay } from "vant";
import CImage from "./CImage.vue";
import { mapState, mapMutations } from "vuex";
export default {
    name: "c-dialog",
    components: {
        [Overlay.name]: Overlay,
        [CImage.name]: CImage,
    },
    computed: {
        ...mapState({
            showCDialog: (state) => state.showCDialog,
            CDialogTxt: (state) => state.CDialogTxt,
        }),
    },
    methods: {
        ...mapMutations({
            setCDialog: "SET_C_DIALOG",
            setCDialogTxt: "SET_C_DIALOG_TXT",
        }),
        onClose() {
            this.setCDialog(false);
        },
    },
};
</script>

<style>

</style>