import axios from 'axios';
import { Base64 } from 'js-base64';
import md5 from 'js-md5';
import Qs from 'qs'
import { randomString, dialogAlert2, closeToast, toast } from './util'
import VueCookies from 'vue-cookies';
import router from '@/router';


const DEV_URL = 'https://t.design006.com/mobile'     //测试接口地址
const URL = 'https://www.design006.com/mobile'     //正式接口地址


const DEBUG = process.env.NODE_ENV == 'development';    //是否是测试环境
const BASE_URL = DEBUG ? DEV_URL : URL
const SUCCESS_CODE = 0;   //接口成功返回code值

// POST请求
function requestApi_post(apiName, params = {}) {
    return new Promise((resolve, reject) => {
        let _apiUrl = BASE_URL + apiName;   //接口地址

        if (DEBUG) {
            console.log('Request Post', _apiUrl);
            console.log('Post Params', params);
        }

        let _options = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        let _assistParams = getAssistParams(params);

        axios.post(_apiUrl, Qs.stringify(Object.assign(params, _assistParams)), _options).then(res => {

            let _code = res.data.code;           //接口返回code值

            if (_code == SUCCESS_CODE) {    //接口返回成功
                if (DEBUG) {
                    console.log('Response Post', _apiUrl);
                    console.log('Response Post Success', res.data.data);
                }
                resolve(res.data.data)
            } else if (_code > 100 && _code < 200) {
                closeToast();
                if (DEBUG) {
                    console.error('Response Post', _apiUrl);
                    console.error('Response Fail', res.data.msg);
                }
                dialogAlert2(res.data.msg)
                reject(res.data.msg)
            } else if (_code == 888) {
                closeToast();
                if (DEBUG) {
                    console.error('Response Post', _apiUrl);
                    console.error('Response Fail', res.data.msg);
                }
                router.push({ name: 'login' })
                toast(res.data.msg)
                reject(res.data.msg)
            } else {
                closeToast();    //接口返回失败
                if (DEBUG) {
                    console.error('Response Post', _apiUrl);
                    console.error('Response Fail', res.data.msg);
                    // toast(res.data.msg)
                }
                reject(res.data.msg)
            }

        }).catch(err => {
            closeToast();              //接口报错
            if (DEBUG) console.error(err);
            toast(err)
            reject(err)
        })
    })
}

// GET请求
function requestApi_get(apiName, params = {}) {
    return new Promise((resolve, reject) => {
        let _apiUrl = BASE_URL + apiName;   //接口地址

        if (DEBUG) {
            console.log('Request Get', _apiUrl);
            console.log('Get Params', params);
        }

        let _assistParams = getAssistParams(params);

        axios.get(_apiUrl, { params: Object.assign(params, _assistParams) }).then(res => {

            let _code = res.data.code;

            if (_code == SUCCESS_CODE) {
                if (DEBUG) {
                    console.log('Response GET', _apiUrl);
                    console.log('Response Get Success', res.data.data);
                }
                resolve(res.data.data)
            } else if (_code > 100 && _code < 200) {
                closeToast();
                if (DEBUG) {
                    console.error('Response GET', _apiUrl)
                    console.error('Response Fail', res.data.msg);
                }
                dialogAlert2(res.data.msg)
                reject(res.data.msg)
            } else if (_code == 888) {
                closeToast();
                if (DEBUG) {
                    console.error('Response GET', _apiUrl)
                    console.error('Response Fail', res.data.msg);
                }
                router.push({ name: 'login' })
                toast(res.data.msg)
                reject(res.data.msg)
            } else {
                closeToast();
                if (DEBUG) {
                    console.error('Response GET', _apiUrl)
                    console.error('Response Fail', res.data.msg);
                    // toast(res.data.msg)
                }
                reject(res.data.msg)
            }

        }).catch(err => {
            closeToast();
            if (DEBUG) console.error(err);
            toast(err)
            reject(err)
        })
    })
}

function getAssistParams(params) {
    let _timestamp = parseInt(new Date().getTime() / 1000);
    let _noncestr = randomString(8)
    let _sign = ''

    let _params = Object.assign(params, { timestamp: _timestamp, noncestr: _noncestr })
    if (VueCookies.isKey('user_id')) _params = Object.assign(_params, { uuid: VueCookies.get('user_id') })


    Object.keys(_params).sort().map(key => {
        _sign += key + "=" + params[key]
    })

    return {
        timestamp: _timestamp,
        noncestr: _noncestr,
        sign: md5(Base64.encode(_sign))
    }
}

export {
    requestApi_post,
    requestApi_get,
}