<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>

    <c-dialog></c-dialog>
    <c-horizontal-cue :show="showCue"></c-horizontal-cue>
  </div>
</template>

<script>
import CHorizontalCue from "./components/CHorizontalCue.vue";
import CDialog from "./components/CDialog.vue"
import { isMobile, deviceInfo, getQueryString } from "./lib/util";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
    components: {
        [CHorizontalCue.name]: CHorizontalCue,
        [CDialog.name]: CDialog
    },
    data() {
        return {
            showCue: false,
        };
    },
    computed: {
        ...mapState({
            routeName: state => state.routeName,
            productId: state => state.product.productId,
            commonId: state => state.commonId
        })
    },
    methods: {
        ...mapActions({
            requestInfo2: "me/requestInfo2",
        }),
        ...mapMutations({
            setOS: "SET_OS",
            setIsLogin: "SET_IS_LOGIN"
        }),
        onOrientationchange() {
            if (window.orientation == 90 || window.orientation == -90) {
                this.showCue = true;
            } else {
                this.showCue = false;
            }
        },
        checkUrl() {
            let _baseUrl = "https://www.design006.com";
            let _inviteCode = this.$cookies.get('invite_code') || getQueryString('inviteCode')
            let _pInviteCode = '?inviteCode=' + _inviteCode;
            let _url = "";


            if (this.routeName == 'home') {
                _url = _inviteCode ? `${_baseUrl}${_pInviteCode}` : _baseUrl;
            } else if (this.routeName == 'detail') {
                _url = _inviteCode ? `${_baseUrl}/detail-${this.commonId}${_pInviteCode}` : `${_baseUrl}/detail-${this.commonId}`;
            } else if (this.routeName == 'homepage') {
                let _tab = getQueryString('tab');
                if (_tab == 0) {
                    _url = _inviteCode ? `${_baseUrl}/homepage-${this.commonId}${_pInviteCode}` : `${_baseUrl}/homepage-${this.commonId}`;
                } else if (_tab == 1) {
                    _url = _inviteCode ? `${_baseUrl}/follow-${this.commonId}${_pInviteCode}` : `${_baseUrl}/follow-${this.commonId}`;
                }else if(_tab == 2){
                    _url = _inviteCode ? `${_baseUrl}/find-${this.commonId}${_pInviteCode}` : `${_baseUrl}/find-${this.commonId}`;
                } else if (_tab == 3) {
                    _url = _inviteCode ? `${_baseUrl}/fans-${this.commonId}${_pInviteCode}` : `${_baseUrl}/fans-${this.commonId}`;
                }
            } else if (this.routeName == 'share') {
                let _code = getQueryString('code');
                _url = `${_baseUrl}/invite?code=${_code}`;
            } else {
                _url = _inviteCode ? `${_baseUrl}${_pInviteCode}` : _baseUrl;
            }

            location.href = _url;
            return false;
        }
    },
    created() {
        this.setOS(deviceInfo());

        if (!isMobile()) {  //判断是否是PC端
            this.checkUrl();    //跳转PC指定页面
        } else {
            if (this.$cookies.isKey("user_id")) {
                this.requestInfo2({ user_id: this.$cookies.get("user_id") }).then(() => {
                    this.setIsLogin(true)
                });

            }
        }
    },
    mounted() {
        this.onOrientationchange();
        window.addEventListener(
            "onorientationchange" in window ? "orientationchange" : "resize",
            this.onOrientationchange,
            false
        );
    },
    destroyed() {
        window.removeEventListener(
            "onorientationchange" in window ? "orientationchange" : "resize",
            this.onOrientationchange,
            false
        );
    },
};
</script>

<style>

</style>
