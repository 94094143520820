export const routeList=[
    
    {
        path: '/',
        component: () => import('../components/layout/BasicLayout.vue'),
        redirect: '/',
        children: [
            {
                name: 'home',
                path:'/',
                component: () => import('../views/home/Home.vue'),
                meta: {
                    title: '首页',
                    checkLogin: false,
                    keepAlive:false,
                    savePosition:true,
                }
            },
            {
                name: 'home2',
                path:'/home2',
                component: () => import('../views/home/Home2.vue'),
                meta: {
                    title: '首页',
                    checkLogin: false,
                    keepAlive:false,
                    savePosition:false,
                }
            },
            {
                name: 'products',
                path:'/products',
                component: () => import('../views/product/Products.vue'),
                meta: {
                    title: '作品列表',
                    checkLogin: false,
                    keepAlive:true,
                    savePosition:true,
                }
            },
            {
                name: 'detail',
                path:'/detail/:id',
                component: () => import('../views/product/Detail.vue'),
                meta: {
                    title: '作品详情',
                    checkLogin: false,
                    keepAlive:false,
                    savePosition:false,
                }
            },
            {
                path:'/homepage/:id',
                name: 'homepage',
                component: () => import('../views/me/Me.vue'),
                meta: {
                    title: '我的主页',
                    checkLogin: false,
                    keepAlive:true,
                    savePosition:true,
                }
            },
            {
                path:'/collect',
                name: 'collect',
                component: () => import('../views/me/Collect.vue'),
                meta: {
                    title: '我的收藏',
                    checkLogin: true,
                    keepAlive:true,
                    savePosition:true,
                }
            },
            {
                path:'/invitation',
                name: 'invitation',
                component: () => import('../views/me/Invitation.vue'),
                meta: {
                    title: '我的邀请',
                    checkLogin: true,
                    keepAlive:false,
                    savePosition:true,
                }
            },
            {
                name: '404',
                path:'/404',
                component: () => import('../views/404.vue'),
                meta: {
                    title: '404',
                    checkLogin: false,
                    keepAlive:false,
                    savePosition:false,
                }
            },
        ]
    },
    {
        path:'/user*',
        component: () => import('../components/layout/PageLayout.vue'),
        redirect: '/user/login',
        children: [
            {
                name: 'login',
                path:'/user/login',
                component: () => import('../views/login/Login.vue'),
                meta: {
                    title: '登录',
                    checkLogin: false,
                    keepAlive:false,
                    savePosition:false,
                }
            },
            {
                path:'/user/register',
                name: 'register',
                component: () => import('../views/login/Register.vue'),
                meta: {
                    title: '注册',
                    checkLogin: false,
                    keepAlive:false,
                    savePosition:false,
                }
            },
            {
                path:'/user/forget',
                name: 'forget',
                component: () => import('../views/login/Forget.vue'),
                meta: {
                    title: '忘记密码',
                    checkLogin: false,
                    keepAlive:false,
                    savePosition:false,
                }
            },
            {
                path:'/user/verify',
                name: 'verify',
                component: () => import('../views/login/Verify.vue'),
                meta: {
                    title: '手机号验证',
                    checkLogin: false,
                    keepAlive:true,
                    savePosition:false,
                }
            },
            {
                path:'/user/invite',
                name: 'invite',
                component: () => import('../views/login/Invite.vue'),
                meta: {
                    title: '注册邀请',
                    checkLogin: false,
                    keepAlive:false,
                    savePosition:false,
                }
            },
            {
                path:'/user/bind',
                name: 'bind',
                component: () => import('../views/login/Bind.vue'),
                meta: {
                    title: '邮箱绑定',
                    checkLogin: false,
                    keepAlive:false,
                    savePosition:false,
                }
            },
            {
                path:'/user/collect_product',
                name: 'collect_product',
                component: () => import('../views/product/CollectProduct.vue'),
                meta: {
                    title: '作品收藏',
                    checkLogin: true,
                    keepAlive:true,
                    savePosition:false,
                }
            },
            {
                path:'/user/tags',
                name: 'tags',
                component: () => import('../views/me/Tags.vue'),
                meta: {
                    title: '我的标签',
                    checkLogin: true,
                    keepAlive:false,
                    savePosition:false,
                }
            },
            {
                path:'/user/agree',
                name: 'agree',
                component: () => import('../views/me/Agree.vue'),
                meta: {
                    title: '网站协议',
                    checkLogin: false,
                    keepAlive:false,
                    savePosition:false,
                }
            },
            {
                path:'/user/copyright',
                name: 'copyright',
                component: () => import('../views/me/Copyright.vue'),
                meta: {
                    title: '版权声明',
                    checkLogin: false,
                    keepAlive:false,
                    savePosition:false,
                }
            },
            {
                path:'/user/add_tag',
                name: 'add_tag',
                component: () => import('../views/me/AddTag.vue'),
                meta: {
                    title: '添加标签',
                    checkLogin: true,
                    keepAlive:false,
                    savePosition:false,
                    lv3:true,
                }
            },
        ]
    },
    {
        path:'/share',
        name: 'share',
        component: () => import('../views/me/Share.vue'),
        meta: {
            title: '分享',
            checkLogin: false,
            keepAlive:false,
            savePosition:false,
        }
    },
    {
        path: '*',
        redirect: '/404'
    },
]


export const menus = [
    {
        name: "我的主页",
        img: "nav-my",
        route: "homepage",
    },
    {
        name: "我的收藏",
        img: "nav-collection",
        route: "collect",
    },
    {
        name: "我的邀请",
        img: "nav-invitatiion",
        route: "invitation",
    },
]
