import { requestApi_get } from '../../lib/api';
import { toastLoading, closeToast } from '../../lib/util'
import VueCookies from 'vue-cookies'

const initFormat = {
    prevData: {
        id: '',
        prefix_id: '',
        preview_image: '',
        file_number: '',
        workname: '',
        work_form: '',
        color_pattern_name: '',
        file_size: '',
        resolving_power: '',
        file_width: '',
        file_height: '',
        work_keywords_arr: [],
    },
    productData: {
        collect: 0,
        designer: {
            id: '',
            head_img: '',
            nickname: '',
            user_level: 0,
            user_level_name: ''
        },
        similar_works: [],
        designer_works: [],
        ad_list:{
            object_name:'',
            image_link:'',
        }
    },
}

const state = {
    productData: initFormat.productData,
    prevData: initFormat.prevData,
    productId: 0,
}

const actions = {
    // 作品详情
    requestDetail({ commit }, params = {}) {
        return new Promise((resolve) => {
            commit('SET_PRODUCT_DATA', initFormat.productData)

            let _uuid = VueCookies.get('user_id')

            requestApi_get('/works/detail', _uuid ? Object.assign(params, { uuid: _uuid }) : params).then(res => {
                commit('SET_PRODUCT_DATA', res)
                resolve();
            })
        })
    },

    requestDetailPreview({ commit }, params = {}) {
        return new Promise((resolve,reject) => {
            commit('SET_PREV_DATA', initFormat.prevData)

            let _uuid = VueCookies.get('user_id')

            requestApi_get('/works/detail_preview', _uuid ? Object.assign(params, { uuid: _uuid }) : params).then(res => {
                commit('SET_PREV_DATA', res);
                resolve(res);
            }).catch(() => {
                reject();
            })
        })
    }
}

const mutations = {
    SET_PRODUCT_DATA(state, data) {
        state.productData = data;
    },
    SET_PRODUCT_ID(state, data) {
        state.productId = data;
    },
    SET_PREV_DATA(state, data) {
        state.prevData = data;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}