import { requestApi_get } from '../../lib/api';
import { toastLoading, closeToast } from '../../lib/util'

const initFormat={
    indexData: {
        designer_list: [],   //设计师
        homepage_banner: {   //首页banner
            img_url: '',
            color: '1',
            link: ''
        },
        theme_list: []   //分类标签
    },
}

const state = {
    indexData: initFormat.indexData

}

const actions = {
    // 首页接口
    requestIndex({ commit }, params = {}) {
        return new Promise(resolve => {
            requestApi_get('/index/index', params).then(res => {
                commit('SET_INDEX_DATA', res)
                resolve()
            })
        })
    },
}

const mutations = {
    SET_INDEX_DATA(state, data) {
        state.indexData = data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}