import { requestApi_get } from '../../lib/api';
const state = {

}

const actions = {
    requestGetCode({commit},params={}){
        return new Promise(resolve=>{
            requestApi_get('/test/get_code',params).then(res=>{
                resolve(res)
            })
        })
    },
    requestSendCode({commit},params={}){
        return new Promise(resolve=>{
            requestApi_get('/test/send_code',params).then(res=>{
                resolve(res)
            })
        })
    }
}

const mutations = {
   
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}